import {APP_INITIALIZER, ApplicationConfig, DEFAULT_CURRENCY_CODE, InjectionToken} from '@angular/core';
import {NavigationStart, provideRouter, Router, withInMemoryScrolling} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {environment} from "../environments/environment";
import {provideStore, Store} from '@ngxs/store';
import {ProductState} from "./shared/state/product-state";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {firstValueFrom} from "rxjs";
import {CategoryState, LoadCategories} from "./shared/state/category-state";
import {provideCustomImageLoader} from "./shared/services/image-loader";
import {ResetErrors} from "./shared/state/common";
import {IMAGE_CONFIG} from "@angular/common";
import {AdminSettingsState} from "./shared/state/admin-settings-state";
import {ShoppingCartState} from "./shared/state/shopping-cart-state";
import {StorageOption, withNgxsStoragePlugin} from "@ngxs/storage-plugin";
import {TaxCategoryState} from "./shared/state/tax-category-state";

export const APP_NAME = new InjectionToken<string>('appName');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'}),
    ),
    provideClientHydration(),
    provideStore(
      [ProductState, CategoryState, AdminSettingsState, ShoppingCartState, TaxCategoryState],
      {developmentMode: !environment.production},
      ...environment.plugins || [],
      withNgxsStoragePlugin({
        keys: [ShoppingCartState],
        namespace: environment.tenant,
        storage: StorageOption.LocalStorage,
      })
    ),
    // provideCloudflareLoader(environment.api),
    provideCustomImageLoader(),
    {provide: APP_NAME, useValue: environment.tenantDisplayName},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => () => firstValueFrom(store.dispatch(new LoadCategories()), {defaultValue: 0}),
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store, router: Router) => () => {
        router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
            store.dispatch(new ResetErrors())
          }
        })
      },
      multi: true,
      deps: [Store, Router],
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 30,
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      }
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.currency,
    },
  ]
};


